
import _ from "lodash";
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import useMockExam from "@/hooks/useMockExam";
import { useStore, MutationNames } from "@/store";
import Countdown from "vue3-countdown";
import CustomAudio from "@/components/CustomAudio.vue";
import ChoiceQuestion from "@/components/ChoiceQuestion.vue";
import MultipleQuestion from "@/components/MultipleQuestion.vue";
import TrueOrFalseQuestion from "@/components/TrueOrFalseQuestion.vue";
import SheetMusicCreation from "@/components/SheetMusicCreation.vue";
import SheetMusicSing from "@/components/SheetMusicSing.vue";
import SheetMusicSong from "@/components/SheetMusicSong.vue";
import BriefAnswerQuestion from "@/components/BriefAnswerQuestion.vue";
import FillsInQuestion from "@/components/FillsInQuestion.vue";
import ConnectQuestion from "@/components/ConnectQuestion.vue";
import NestingQuestion from "@/components/NestingQuestion.vue";
import JigsawQuestion from "@/components/JigsawQuestion.vue";
import DragQuestion from "@/components/DragQuestion.vue";
import ColorWheelQuestion from "@/components/ColorWheelQuestion.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { onBeforeRouteLeave } from "vue-router";
import { checkLockFree, lock, unLock } from "@/utils/liner-lock";

export default defineComponent({
  components: {
    CustomAlert,
    Countdown,
    CustomAudio,
    ChoiceQuestion,
    MultipleQuestion,
    TrueOrFalseQuestion,
    SheetMusicCreation,
    SheetMusicSing,
    SheetMusicSong,
    BriefAnswerQuestion,
    FillsInQuestion,
    ConnectQuestion,
    NestingQuestion,
    JigsawQuestion,
    DragQuestion,
    ColorWheelQuestion,
  },
  setup() {
    let loading: any = ref(""); // 切换题目的时候增加loading
    let mockPaperHistoryLoading = ref(false);
    let mockPaperHistoryText = ref("处理中...");
    const { submitPapers: _submitPapers } = useMockExam();
    const questionRef: any = ref<null | HTMLElement>(null);
    const customAlertRef: any = ref<null | HTMLElement>(null);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let showCustomAlert: any = ref(false); // 是否弹窗
    let alertInfo: any = ref({}); // 弹窗信息
    let question: any = ref({}); // 试题信息
    let examInfo: any = ref({}); // 考试信息
    let paperList: any = ref([]); // 试卷列表信息
    let borderBottomList: any = ref([]); // 最后一行试卷下边框
    let paperIndex: any = ref(0); // 当前选中的试卷
    let borderBottomRightRadius: any = ref(); // 试卷左下角边框
    let borderBottomLeftRadius: any = ref(); // 试卷右下角边框
    let alias: any = ref([]); // 试卷信息
    let indexInPart: any = ref(1); // 题目大题序号
    let indexInQuestion: any = ref(1); // 题目小题序号
    let btnNextShow = ref(true);
    let nextBtnStyle = ref({ visibility: "visible" });
    let btnPreviousShow = ref(false);
    let time = ref(0); // 距离考试结束剩余时间
    let PLATFORM = ref(0); // 当前环境 1｜Android 0｜pc
    const name = ref(store.state.name);
    const grade = ref(store.state.grade);
    const classNumber = ref(store.state.classNumber);
    const examType = route.query.examType; // 当前试卷状态 histroy:历史记录
    let questionDisable = ref(true); // 题目能否编辑状态 true:不可编辑｜｜false:可以编辑
    const countdownRef = ref<any>({});
    const countdownShow = ref(true);

    onBeforeRouteLeave((to, from) => {
      countdownShow.value = false;
    });

    const goBack = () => {
      router.push({
        path: "/examRecord",
        query: { type: "mockExam" },
      });
    };

    const handleFinish = () => {
      console.log(777, countdownShow.value);
      if (countdownShow.value) {
        submitPapers(true);
      }
    };

    const saveOldQuestionAnswer = async () => {
      try {
        // loading.value = ElLoading.service({
        //   lock: true,
        //   text: "处理中...",
        //   background: "rgba(0, 0, 0, 0.3)",
        // });
        mockPaperHistoryLoading.value = true;
        console.log("questionRef222", questionRef.value);
        console.log("examInfo111", examInfo.value);
        let AnswerInfo;
        if (!questionRef?.value?.getAnswer) {
          AnswerInfo = { type: 0 };
        } else {
          AnswerInfo = await questionRef.value.getAnswer();
        }
        console.log("AnswerInfo1111", AnswerInfo);
        changePartsAnswer(AnswerInfo);
      } catch (e) {
        console.error(e);
        ElMessage({
          type: "warning",
          offset: 100,
          message: "保存答案失败，请重试！",
          center: true,
        });
        unLock();
      } finally {
        // loading.value && loading.value.close();
        mockPaperHistoryLoading.value = false;
      }
    };

    const changePartsAnswer = (params: any) => {
      const { type, answer_params = {} } = params || {};
      if (type) {
        const { parentId, questionId, answer } = answer_params;
        if (!parentId) {
          // * 正常体型的保存答案
          const nowQuestion =
            alias.value[indexInPart.value - 1].questions[
              indexInQuestion.value - 1
            ];
          const nowAlias = Object.assign(nowQuestion, {
            answer_params: params.answer_params,
          });
          alias.value[indexInPart.value - 1].questions[
            indexInQuestion.value - 1
          ] = nowAlias;
        } else {
          // * 套题题型
          const parentQuestion =
            alias.value[indexInPart.value - 1].questions.find(
              (question: any) => question.id == parentId
            ) || {};
          const { sub_questions = [] } = parentQuestion;
          const sub_question = sub_questions.find(
            (subQuestion: any) => subQuestion.id == questionId
          );
          if (answer.answer_params) {
            _.merge(sub_question, { answer_params: answer.answer_params });
          } else {
            sub_question.answer_params = answer.answer_params;
          }
        }
      }
    };

    // 选中的题目
    const handlePartOrQuestion = _.throttle(
      async (indexPart: number, indexQuestion: number) => {
        lock();
        try {
          await saveOldQuestionAnswer();
        } catch (err) {
          unLock();
          return;
        }

        // 选择的是当前题目，就不执行下面的步骤
        if (
          indexPart + 1 === indexInPart.value &&
          indexQuestion + 1 === indexInQuestion.value
        ) {
          unLock();
          return;
        }
        indexInPart.value = indexPart + 1;
        indexInQuestion.value = indexQuestion + 1;
        question.value = alias.value[indexPart].questions[indexQuestion];
        question.value.indexInPart = indexInPart.value;
        question.value.indexInQuestion = indexInQuestion.value;
        const inPartNum = alias.value.length; // 当前试卷总大题数
        const inQuestionNum =
          alias.value[indexInPart.value - 1].questions.length; // 当前试卷大题下总小题数
        // if ((indexPart > 0 || indexQuestion > 0) && paperIndex.value !== 1) {
        //   btnPreviousShow.value = true;
        // } else {
        //   btnPreviousShow.value = false;
        // }
        if (
          indexInPart.value === 1 &&
          indexInQuestion.value === 1 &&
          paperIndex.value == 0
        ) {
          btnPreviousShow.value = false;
        } else {
          btnPreviousShow.value = true;
        }
        if (
          indexPart + 1 === inPartNum &&
          indexQuestion + 1 === inQuestionNum &&
          question.value.type !== 11
        ) {
          btnNextShow.value = false;
          nextBtnStyle.value = { visibility: "hidden" };
        } else {
          btnNextShow.value = true;
          nextBtnStyle.value = { visibility: "visible" };
        }
        // 更新最新已答题目数
        const newestExamInfo = store.getters.allPaperQuestionsStatistics;
        paperList.value = newestExamInfo;
      },
      800
    );

    const getNext = _.throttle(async () => {
      /**
       * 下一题
        1. 普通题目
            1. 如果是最后一份试卷最后一题 -不显示下一题
            2. 点击切换题目
                1. 如果有当前试卷还没完就切换下一题
                2. 如果有下一份试卷切换下一份试卷第一题
        2. 套题题目
            1. 如果是套题最后一题，运行普通切题的逻辑
            2. 如果不是最后道题，切换下一道小题
       */

      console.log(1, questionRef.value);
      if (!questionRef.value) {
        return;
      }
      //* 上一题按钮必定显示
      btnPreviousShow.value = true;
      // * 判断当前题型
      const { type } = question.value;

      // 前往下一题
      let { isLastSubQuestion, changeSubQuestion } = questionRef.value;

      const goNext = async () => {
        // 是不是最后一道题，并且还有下一份试卷
        const isHasNextPaper =
          paperIndex.value < paperList.value.length - 1 &&
          indexInPart.value === alias.value.length &&
          indexInQuestion.value ===
            alias.value[indexInPart.value - 1].questions.length;
        if (isHasNextPaper) {
          console.log("模拟考应该只有一份试卷");
        } else {
          // 切换到下一题
          await saveOldQuestionAnswer();
          const isLastPartQuestion =
            indexInQuestion.value ===
            alias.value[indexInPart.value - 1].questions.length;
          if (isLastPartQuestion) {
            // 大题的最后题 切换到下一个大题的第一题
            indexInPart.value += 1;
            indexInQuestion.value = 1;
          } else {
            indexInQuestion.value += 1;
          }
          question.value =
            alias.value[indexInPart.value - 1].questions[
              indexInQuestion.value - 1
            ];
          question.value.indexInPart = indexInPart.value;
          question.value.indexInQuestion = indexInQuestion.value;
        }
      };

      // 设置锁，保证在数据渲染完成前不会被自动交卷
      lock();
      if (type !== 11) {
        // 普通题目
        await goNext();
      } else {
        // 套题题目
        if (isLastSubQuestion()) {
          await goNext();
          isLastSubQuestion = questionRef.value.isLastSubQuestion;
        } else {
          await saveOldQuestionAnswer();
          changeSubQuestion("next");
        }
      }
      const isExamLastQuestion =
        paperList.value.length - 1 === paperIndex.value &&
        indexInPart.value === alias.value.length &&
        indexInQuestion.value ===
          alias.value[indexInPart.value - 1].questions.length;
      if (
        (isExamLastQuestion && question.value.type !== 11) ||
        (isExamLastQuestion &&
          question.value.type === 11 &&
          isLastSubQuestion &&
          isLastSubQuestion())
      ) {
        // 试卷最后一道题 下一题按钮必定隐藏
        btnNextShow.value = false;
        nextBtnStyle.value = { visibility: "hidden" };
      }
      // 自动定位到题目位置
      const elemId = "question" + question.value.id;
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.scrollIntoView();
      }
      // 更新最新已答题目数
      const newestExamInfo = store.getters.allPaperQuestionsStatistics;
      paperList.value = newestExamInfo;
    });

    const getPrevious = _.throttle(async () => {
      /** 上一题
        1. 普通题目
            1. 如果是第一份试卷第一题 -不显示上题
            2. 点击切换题目
                1. 如果有当前试卷还没完就切换上一题
                2. 如果有多份试卷，切换到上一份试卷的最后一道题
        2. 套题题目
            1. 如果是套题第一道题，运行普通切题的逻辑
            2. 如果不是第一道题，切换上一道小题
      */
      //* 下一题按钮必定显示
      btnNextShow.value = true;
      nextBtnStyle.value = { visibility: "visible" };
      // * 判断当前题型
      const { type } = question.value;
      // 前往上一题
      const goPrevious = async () => {
        // 是不是第一题，并且还有上份试卷
        const isHasPreviousPaper =
          indexInPart.value === 1 &&
          indexInQuestion.value === 1 &&
          paperList.value.length > 1 &&
          paperIndex.value !== 0;
        if (isHasPreviousPaper) {
          console.log("模拟考应该只有一份试卷");
        } else {
          // 切换到上一题
          await saveOldQuestionAnswer();
          const isFirstPartQuestion = indexInQuestion.value === 1;
          if (isFirstPartQuestion) {
            // 大题的第一题 切换到上一个大题的最后一题
            indexInPart.value -= 1;
            indexInQuestion.value =
              alias.value[indexInPart.value - 1].questions.length;
          } else {
            indexInQuestion.value -= 1;
          }
          question.value =
            alias.value[indexInPart.value - 1].questions[
              indexInQuestion.value - 1
            ];
          question.value.indexInPart = indexInPart.value;
          question.value.indexInQuestion = indexInQuestion.value;
        }
      };
      const { isFirstSubQuestion, changeSubQuestion } = questionRef.value;
      // 设置锁，保证在数据渲染完成前不会被自动交卷
      lock();
      if (type !== 11) {
        // 普通题目
        await goPrevious();
      } else {
        // 套题题目
        if (isFirstSubQuestion()) {
          await goPrevious();
        } else {
          await saveOldQuestionAnswer();
          changeSubQuestion("previous");
        }
      }

      const isExamFirstQuestion =
        paperIndex.value === 0 &&
        indexInPart.value === 1 &&
        indexInQuestion.value === 1;
      if (
        (isExamFirstQuestion && question.value.type !== 11) ||
        (isExamFirstQuestion &&
          question.value.type === 11 &&
          isFirstSubQuestion &&
          isFirstSubQuestion())
      ) {
        // 试卷第一道题 上一题按钮必定隐藏
        btnPreviousShow.value = false;
      }
      // 自动定位到题目位置
      const elemId = "question" + question.value.id;
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.scrollIntoView();
      }
      // 更新最新已答题目数
      const newestExamInfo = store.getters.allPaperQuestionsStatistics;
      paperList.value = newestExamInfo;
    });

    onBeforeMount(() => {
      const papers = store.getters.allPaperQuestionsStatistics;
      store.commit(MutationNames.CHANGE_PAPERS, papers);
      examInfo.value = store.state.examInfo?.active_exam;
      // examInfo.value = "考试";
      paperList.value = store.state.papers;
      alias.value = (store.state.papers as any)[
        paperIndex.value
      ].parperParts.parts;
      question.value =
        alias.value[indexInPart.value - 1].questions[indexInQuestion.value - 1];
      question.value.indexInPart = indexInPart.value;
      question.value.indexInQuestion = indexInQuestion.value;

      let paper_ids: any = [];
      paperList.value.forEach((item: any) => {
        paper_ids.push(item.id);
        // 执行代码
      });

      if (examType !== "histroy") {
        questionDisable.value = false;
        // const inTime = moment(new Date());
        // const onTime = moment(examInfo.value.end_time);
        // const timeGap: any = moment.duration(onTime.diff(inTime, "seconds"));
        // 考试结束时间是最迟考试开始时间
        time.value = examInfo.value.time * 1000;

        // if (examInfo.value.time * 1000 > timeGap * 1000) {
        //   // 使用最后截止时间时长
        //   time.value = timeGap * 1000;
        //   console.log("11rrr", timeGap * 1000);
        // } else {
        //   // 使用考试时长
        //   time.value = examInfo.value.time * 1000;
        //   console.log("22rrr", examInfo.value.time * 1000);
        // }
      }
    });

    const submitPapers = async (timEnd = false) => {
      alertInfo.value.title = "提交确认";
      alertInfo.value.info = "是否确定提交答卷?";
      alertInfo.value.btnTxt = ["确定", "取消"];
      if (timEnd) {
        ElMessageBox.close();
      }

      // let load;
      try {
        // load = ElLoading.service({
        //   lock: true,
        //   text: "考试答案处理中...",
        //   background: "rgba(0, 0, 0, 0.3)",
        // });
        mockPaperHistoryText.value = "考试答案处理中...";
        mockPaperHistoryLoading.value = true;
        await checkLockFree();
        // 超过5分钟会自动释放锁
      } catch (e) {
        // load?.close();
        mockPaperHistoryLoading.value = false;
        mockPaperHistoryText.value = "处理中...";
        console.error(e);
      }

      // showCustomAlert.value = true;
      await saveOldQuestionAnswer();
      // 保存答案 不能乱放只能放这里
      _submitPapers(store.state.MAC || "test", PLATFORM.value, timEnd);
    };

    const isHasAnswer = (question: any) => {
      if (examType === "histroy") {
        // correctness 0|错，1｜对，2｜部分正确
        if (question.type !== 11) {
          if (question.correctness === 1) {
            return "#1ac36e";
          } else {
            return "#E14339";
          }
        } else {
          const subAnswerLen = question.sub_questions.filter(
            (sub: any) => sub.correctness === 1
          ).length;
          if (subAnswerLen !== question.sub_questions.length) {
            return "#E14339";
          } else {
            return "#1ac36e";
          }
        }
      } else {
        if (question.type !== 11) {
          if (!question.answer_params) {
            return "#dff1e4";
          } else {
            return "#1ac36e";
          }
        } else {
          const subAnswerLen = question.sub_questions
            .map((sub: any) => sub.answer_params)
            .filter(Boolean).length;
          if (subAnswerLen !== question.sub_questions.length) {
            return "#dff1e4";
          } else {
            return "#1ac36e";
          }
        }
      }
      // if (question.type !== 11) {
      //   return !question.answer_params;
      // }
      // const subAnswerLen = question.sub_questions
      //   .map((sub: any) => sub.answer_params)
      //   .filter(Boolean).length;
      // return subAnswerLen !== question.sub_questions.length;
    };
    const isHasAnswerColor = (question: any) => {
      if (examType === "histroy") {
        return "#f7f7f7";
      } else {
        if (question.type !== 11) {
          if (!question.answer_params) {
            return "#1ac36e";
          } else {
            return "#dff1e4";
          }
        } else {
          const subAnswerLen = question.sub_questions
            .map((sub: any) => sub.answer_params)
            .filter(Boolean).length;
          if (subAnswerLen !== question.sub_questions.length) {
            return "#1ac36e";
          } else {
            return "#dff1e4";
          }
        }
      }
    };

    onMounted(() => {
      let isAndroid = true;
      const device = navigator.userAgent;
      isAndroid = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      if (isAndroid) {
        PLATFORM.value = 1;
      }

      // 试卷切换样式控制
      if (paperList.value.length % 2 === 0) {
        borderBottomList.value = [
          paperList.value.length - 1,
          paperList.value.length - 2,
        ];
        borderBottomLeftRadius.value = paperList.value.length - 2;
        borderBottomRightRadius.value = paperList.value.length - 1;
      } else {
        borderBottomList.value = [paperList.value.length - 1];
        borderBottomLeftRadius.value = paperList.value.length - 1;
      }
      console.log("paperListqqq", paperList);
    });
    return {
      countdownShow,
      countdownRef,
      customAlertRef,
      questionRef,
      paperList,
      question,
      borderBottomList,
      paperIndex,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      alias,
      indexInPart,
      indexInQuestion,
      btnPreviousShow,
      btnNextShow,
      examInfo,
      name,
      grade,
      classNumber,
      time,
      nextBtnStyle,
      changePartsAnswer,
      handlePartOrQuestion,
      getNext,
      getPrevious,
      submitPapers,
      examType,
      goBack,
      questionDisable,
      alertInfo,
      showCustomAlert,
      isHasAnswer,
      handleFinish,
      isHasAnswerColor,
      mockPaperHistoryLoading,
      mockPaperHistoryText,
    };
  },
});
